<template>
  <el-container class="tokenmgr-index-main">
    <el-main>
      <std-nav :paths="navData"></std-nav>
      <el-card class="list-box">
        <std-table ref="module_table" :cols="userConfig.cols" :data="userConfig.list" :pageindex.sync="userConfig.pageIndex"
          :pagesize.sync="userConfig.pageSize" :dataTotal="userConfig.total" disable-selection remotePaging @refresh="onRefresh">
          <template #action="{row}">
            <div class="btns">
              <el-link icon="el-icon-edit" @click="onEdit(row)">编辑</el-link>
              <el-link icon="el-icon-delete" @click="onDelete(row)" style="margin-left:5px;">删除</el-link>
            </div>
          </template>
          <template #state="{row}">
            <span v-text="StateObj[row.EB_STATE]"></span>
          </template>
        </std-table>
        <component v-if="dialog.show" :is="dialog.type" :data="dialog.data" @cancel="onCloseDialog" @success="dialog.event"></component>
      </el-card>
    </el-main>
    <el-aside>
      <el-card class="actionbox">
        <template #header>
          操作
        </template>
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="onCreateToken">创建新的授权码</el-button>
        <div class="tips">创建一个新的授权码,可用于请求接口和登录查看</div>
      </el-card>
    </el-aside>
  </el-container>
</template>
<script>
import winCreateToken from './win-create-token'
export default {
  components: {
    winCreateToken
  },
  data() {
    return {
      navData: [
        { text: '系统管理', path: "/SysMgr" },
        '授权码管理'
      ],
      FuncButtons: [],
      groupcreate: {
        show: false,
        value: ''
      },
      userConfig: {
        pageIndex: 1,
        pageSize: 15,
        total: 0,
        list: [],
        cols: [
          { prop: 'EB_DESC', label: '授权码名称' },
          { prop: 'EB_STATE', format: 'state', label: '状态', width: 50 },
          { prop: 'EB_START_TIME', label: '生效时间', width: 170 },
          { prop: 'EB_END_TIME', label: '失效时间', width: 170 },
          { format: 'action', width: 130 }
        ],
      },
      StateObj: {
        Normal: '启用',
        Disable: '禁用'
      },
      dialog: {
        show: false,
        type: '',
        data: {},
        event: null
      },
    }
  },
  computed: {
    UserData() {
      return this.$store.state.global.LoginInfo
    },
  },
  methods: {
    onCloseDialog() {
      this.dialog.show = false
    },
    onCreateToken() {
      let me = this
      this.$set(this, 'dialog', {
        show: true,
        type: 'win-create-token',
        data: {
        },
        event(result) {
          me.$set(me, 'dialog', {
            show: false
          })
          if (result.to_edit) {
            this.$router.push({
              name: 'TokenMgrDetail',
              params: {
                token_id: record.EB_TOKEN_ID
              }
            })
          } else {
            me.RefreshList()
          }
        }
      })
    },
    onEdit(record) {
      this.$router.push({
        name: 'TokenMgrDetail',
        params: {
          token_id: record.EB_TOKEN_ID
        }
      })
    },
    onDelete(record) {
      let me = this
      let { EB_USER_ID, EB_TOKEN_ID } = record
      this.Bus.msg_confirm('确定要删除该授权码吗?', _ => {
        me.Bus.ActionFunc("TokenMgr-DeleteToken", {
          user_id: EB_USER_ID,
          token_id: EB_TOKEN_ID
        }, data => {
          me.Bus.msg_success('操作成功!');
          me.RefreshList()
        })
      })
    },
    RefreshList() {
      let me = this
      //获取当前登录用户的ID
      let user_id = this.UserData.user_id
      this.Bus.ActionFunc('TokenMgr-GetList', {
        user_id: user_id,
        page_index: this.userConfig.pageIndex,
        page_size: this.userConfig.pageSize
      }, data => {
        me.$set(me.userConfig, 'list', data.list)
        me.$set(me.userConfig, 'total', data.total)
      })
    },
    onRefresh() {
      this.RefreshList()
    }
  },
  mounted() {
    this.RefreshList()
  }
}
</script>
<style lang="scss">
.tokenmgr-index-main {
  width: 1200px;
  margin: 0 auto;
  > .el-main {
    padding: 0px 20px 10px 0px !important;
    .header-btns {
      float: right;
    }
    .list-box .el-card__body{
      padding:0;
    }
    .btns > .el-link + .el-link {
      margin-right: 5px;
    }
    > .el-card + .el-card {
      margin-top: 10px;
    }
  }
  > .el-main {
    > .el-card {
      margin-bottom: 10px;
    }
  }
  > .el-aside {
    overflow: visible;
    padding-bottom: 10px;

    .el-card {
      margin-bottom: 10px;
    }

    .actionbox {
      .tips {
        font-size: 13px;
        padding: 10px 0;
        color: #626161;
      }
    }

    .list {
      padding-bottom: 10px;
      .main {
        > .el-link {
          float: left;
          clear: both;
        }
      }
    }
  }
}
</style>