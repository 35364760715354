<template>
  <el-dialog title="创建新的授权码" :visible="true" :before-close="onClose" width="600px" class="tokenmgr-win-create-token">
    <el-container>
      <el-main style="padding:0">
        <el-form ref="detail_form" class="form-jg5" label-width="100px" label-position="right" size="mini">
          <el-form-item label="授权码名称">
            <el-input v-model.trim="form.EB_DESC" placeholder="授权码说明"></el-input>
          </el-form-item>
          <el-form-item label="有效期">
            <el-date-picker v-model="form.EB_RANGE" type="datetimerange" value-format="yyyy/MM/dd HH:mm:ss" :picker-options="pickerOptions"
              range-separator="至" start-placeholder="生效时间" end-placeholder="失效时间" align="right">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="form.EB_STATE">
              <el-radio-button label="Normal">启用</el-radio-button>
              <el-radio-button label="Disable">禁用</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer>
        <el-button type="primary" size="mini" icon="el-icon-s-claim" @click="onSave">提交</el-button>
        <el-button type="primary" size="mini" icon="el-icon-s-claim" @click="onSave(true)">提交并编辑权限</el-button>
        <el-button type="primary" size="mini" icon="el-icon-refresh-right" @click="onClose">取消</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    data: Object
  },
  data() {
    const end = new Date();
    const start = new Date();
    end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
    return {
      form: {
        EB_DESC: '',
        EB_RANGE: [
          moment(start).format('yyyy/MM/dd 00:00:00'),
          moment(end).format('yyyy/MM/dd 00:00:00')
        ],
        EB_STATE: 'Normal'
      },
      pickerOptions: {
        shortcuts: [{
          text: '一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [
              moment(start).format('yyyy/MM/dd 00:00:00'),
              moment(end).format('yyyy/MM/dd 00:00:00')
            ]);
          }
        }, {
          text: '一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [
              moment(start).format('yyyy/MM/dd 00:00:00'),
              moment(end).format('yyyy/MM/dd 00:00:00')
            ]);
          }
        }]
      },
    }
  },
  computed: {
    UserData() {
      return this.$store.state.global.LoginInfo
    }
  },
  methods: {
    onClose() {
      this.$emit('cancel')
    },
    onSave(to_edit) {
      let me = this
      let form = this.form
      let postData = {
        user_id: this.UserData.user_id,
        token_desc: form.EB_DESC,
        start_time: form.EB_RANGE[0],
        end_time: form.EB_RANGE[1],
        state: form.EB_STATE
      }
      let valiResult = this.Bus.validData(postData, {
        require: [
          { prop: 'token_desc', name: '授权码名称' },
          { prop: 'start_time', name: '生效开始时间' },
          { prop: 'end_time', name: '生效截止时间' }
        ]
      })
      if (!valiResult) {
        return
      }

      this.Bus.ActionFunc(`TokenMgr-CreateNew`, postData, data => {
        me.Bus.msg_success('保存成功!')
        me.$emit('success', {
          to_edit: !!to_edit
        })
      });
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss">
.tokenmgr-win-create-token {
  .el-dialog {
    > .el-dialog__body {
      .el-container {
        //min-height: 200px;
        > .el-footer {
          text-align: right;
          height: 40px !important;
        }
      }
    }
  }
}
</style>
